//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SvgIcon',
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
}
